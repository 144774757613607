import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { hideNavBar } from "../store/slice/basic-slice";


const ScrollTop = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideNavBar());
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

export default ScrollTop;