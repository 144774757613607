
const NotFound = () => {
    return (
        <div className="title-bar">
            <h1>Not found</h1>
            <p>page not found</p>
        </div>
    )
}

export default NotFound;