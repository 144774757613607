import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../common/helper";



export const fetchCart = createAsyncThunk('carts/fetchCart', async (token) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    try {
        const response = await axios.get(apiUrl + 'my-carts', headers);
        return response.data;
    }
    catch (err) {
        console.log('from thunk error', err)
        return err.message
    }
});

export const addToCart = createAsyncThunk('carts/addToCart', async (product_id, key) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${key}`
    }
    try {
        const response = await axios.post(apiUrl + 'my-carts', {
            product_id: product_id
        }, headers);
        return response.data;
    }
    catch (err) {
        return err.message
    }
});

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        showCart: false,
        cartItems: [],
        cartCount: 0,
        status: 'idle',
        errors: null,
    },
    reducers: {
        openCart(state) {
            state.showCart = true;
        },
        hideCart(state) {
            state.showCart = false;
        },
        addProductToCart(state, action) {
            state.cartCount = action.payload.total_items;
            state.cartItems = action.payload.cart_items;
        },
        updateCartItems(state, action) {
            state.cartCount = action.payload.total_items;
            state.cartItems = action.payload.cart_items;
        },
        emptyCartItems(state) {
            state.cartCount = 0;
            state.cartItems = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.status = 'succeeded';
                console.log('from extra reducers', action.payload)
                // state.cartItems = action.payload.data.data;
                // state.cartCount = action.payload.data.data.total_items;
            })
            .addCase(fetchCart.rejected, (state, action) => {
                console.log('from extra reducers errors', action.payload)
                // state.status = 'failed';
                // state.errors = action.error;
            })
    }
});

export const showCartStatus = (state) => state.cart.showCart;
export const getCartStatus = (state) => state.cart.status;
export const getCartError = (state) => state.cart.errors;
export const getAllCartItems = (state) => state.cart.cartItems;
export const getCartCount = (state) => state.cart.cartCount;

export const { openCart, hideCart, addProductToCart, updateCartItems, emptyCartItems } = cartSlice.actions;

export default cartSlice.reducer;