const SustainablityItem = ({ img_url, download_url }) => {
    return (
        <div className="col-md-4 single-content aw23 grid-item">
            <div className="img-wrapper">
                <img className="p2 img-fluid" src={img_url} />
            </div>
            <div className="text-center">
                <a className="btn from-center mt-1 shadow-md" href={download_url}><i className="fas fa-file-pdf"></i> Download </a>
            </div>
        </div>
    )
}

export default SustainablityItem;