import { useSelector } from "react-redux";
import { showLoginStatus } from "../store/slice/auth-slice";


const useAuth = () => {
    const token = localStorage.getItem('token');
    return token;
    // const isLogin = useSelector(showLoginStatus);
    // return isLogin;
}


export default useAuth;