

const WhyUs = () => {
    return (
        <section className="section-pad pb-5 why_us">
            <div className="container">
                <div
                    className="title_sec text-center position-relative pb-3 mb-5 mx-auto"
                >
                    <h2 className="mb-0 text-uppercase">Why Us? - Duty FREE Table</h2>
                </div>

                <div className="table-responsive colored-table">
                    <table className="table table-bordered table-dark">
                        <thead>
                            <tr>
                                <th scope="col">From/To</th>
                                <th scope="col">Africa(SADC)</th>
                                <th scope="col">USA(AGOA)</th>
                                <th scope="col">European Union</th>
                                <th scope="col">United Kingdom</th>
                                <th scope="col">Canada</th>
                                <th scope="col">Australia</th>
                                <th scope="col">Japan</th>
                                <th scope="col">Norway</th>
                                <th scope="col">China</th>
                                <th scope="col">South Korea</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Mauritius</th>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <th scope="row">Madagascar</th>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>-</td>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">India</th>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <th scope="row">Bangladesh</th>
                                <td>Yes</td>
                                <td>-</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>Yes</td>
                                <td>-</td>
                                <td>Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default WhyUs