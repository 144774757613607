import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { addProductToCart } from "../../store/slice/cart-slice";
import { toast } from 'react-toastify';
import useAuth from "../../hooks/useAuth";
import { hide, show, showVisibleStatus } from "../../store/slice/basic-slice";
import Modal from 'react-bootstrap/Modal';
import Zoom from 'react-img-zoom'

const ProductDetail = () => {

    const dispatch = useDispatch();
    const isLogin = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState('');

    const btnClicked = useSelector(showVisibleStatus);

    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        if (isLogin) {
            setShowModal(true)
        }
        else {
            navigate('/login', {
                replace: true,
                state: {
                    from: location
                },
            })
        }

    };

    const images = [
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03106_3.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03105_3.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-CRSP783.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/IMG_1607copy.jpg',
    ];

    const [product, setProduct] = useState({});
    const { id } = useParams();
    const token = useSelector((state) => state.auth.token);

    const headers = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${token}`
        }
    }

    useEffect(() => {
        const getProductDetails = async () => {
            await axios.get(apiUrl + `products/${id}`, headers)
                .then((res) => {
                    setProduct(res.data.data);
                })
                .catch((e) => {
                    console.log('from products', e)
                });
        }
        getProductDetails();
    }, [])
    const settings = {
        // customPaging: function (i) {
        //     return (
        //         <a>
        //             <img src='https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg' />
        //         </a>
        //     );
        // },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const handleAddToCart = () => {
        dispatch(show());
        setShowModal(false);
        let token = localStorage.getItem('token');
        let payload = {
            product_id: id,
            comments: comments
        }
        axios.post(apiUrl + 'my-carts', payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                let payload = res.data.data;
                dispatch(addProductToCart(payload));
                toast.success('Product added cart', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(hide())
            })
            .catch((e) => {
                console.log(e);
                dispatch(hide())
            })

    }

    return (
        <div className="content-wrapper">
            <section className="section-pad pb-100 Pd-details_Wrapper">
                <div className="container">
                    <div className="col-md-10 m-auto">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="prd-slider">
                                    <ul >
                                        {product?.images?.length > 0 ? (<Slider {...settings}>
                                            {product.images.map((image) => (
                                                <li key={image.id}>
                                                    <Zoom
                                                        img={image.url}
                                                        zoomScale={3}
                                                        width={450}
                                                        height={450}
                                                        transitionTime={0.5}
                                                    />
                                                    {/* <a href="#" className="zoom">
                                                        <img style={{ height: '450px' }} src={image.url} alt="thumbnail" />
                                                    </a> */}
                                                </li>
                                            ))}
                                        </Slider>) : (<div className="image-not-found">
                                            <p style={{ textAlign: 'center' }}>Image coming soon</p>
                                            {/* <img  src="https://testcadomain.com/cdl/public/img/CDL-Charcoal.png" alt="thumbnail" /> */}
                                        </div>)}

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="position-relative details_box">
                                    <p className="reference bg-primary text-white d-inline-block"><b>Reference ID: </b>{product.header_id}</p>
                                    <h2 className="text-uppercase prd-name">{product.title}</h2>
                                    <h6 className="bold text-dark mb-4">Fabric Details:</h6>
                                    <ul className="square-list">
                                        <li><strong>Header id:</strong>  {product.header_id}</li>
                                        <li><strong>Fab cod:</strong>  {product.fab_cod}</li>
                                        <li><strong>Fabric type:</strong>  {product.fab_type}</li>
                                        <li><strong>Density: </strong> {product.fab_density}</li>
                                        <li><strong>Composition: </strong> {product.fab_compo}</li>
                                        <li><strong>Gauge: </strong> {product.gauge}</li>
                                        <li><strong>Finish:</strong> {product.fin_desc}</li>
                                        <li><strong>Count: </strong> {product.fab_count}</li>
                                        <li><strong>Fab desc: </strong> {product.fab_desc}</li>
                                        {product.fab_descriptions && (<li><strong>Fab Composition: </strong>
                                            <ul>
                                                {product.fab_descriptions && product.fab_descriptions.map((fab_comp) => (
                                                    <li key={fab_comp.id}>{fab_comp.description}</li>
                                                ))}
                                            </ul>
                                        </li>)}

                                        {product.yarn_descriptions && (<li><strong>Yarn Composition: </strong>
                                            <ul>
                                                {product.yarn_descriptions && product.yarn_descriptions.map((fab_comp) => (
                                                    <li key={fab_comp.id}>{fab_comp.description}</li>
                                                ))}
                                            </ul>
                                        </li>)}

                                    </ul>
                                    <button type="submit" onClick={handleShow} className="btn from-center btn-dark border-0">Add to Selection</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add to selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea className="w-100" name="more-info" id="" rows="4" placeholder="Write your message..." onChange={(e) => setComments(e.target.value)}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleAddToCart} className="btn from-center btn-dark border-0" disabled={btnClicked}>Add to Selection</button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default ProductDetail;