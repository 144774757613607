import CDLAbout from '../../../assets/img/CDL-about.jpg';

const AboutTopSection = () => {
    return (
        <section className="section-pad pb-100 about">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div
                        className="col-lg-8 me-auto text-start position-relative abt-content px-3"
                    >
                        <p className="mb-1 text-uppercase text-primary">Overview</p>
                        <h2 className="lined lined-left mt-3 mb-4 bs-line-height">
                            At CDL, we cherish durability.
                        </h2>
                        <p className="text-muted">
                            Today, our Mauritius based facility is a world reference to
                            deliver fabrics using recycled raw materials through sustainable
                            processes. From Day 1, we've invested our energy to develop
                            specialty cotton and blended fabrics with tremendous finishes
                            and hand-feel.
                        </p>

                        <p className="text-muted">
                            Looking for a timeless jersey fabric ? Or hunting for a
                            hand-made all over print? <br /><b>We'll do it! </b
                            ><br />Agility? <b>YES.</b> Boredom? <b>NEVER!</b>
                        </p>

                        <p className="text-muted">
                            Our 500 talents bring passion to deliver yearly 3,700T of fabric
                            to demanding manufacturers worldwide including Tropic, our
                            sister company.
                        </p>
                        <div className="counters mt-4 col-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div
                                        className="counter-box text-start wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.3s"
                                    >
                                        <span className="d-block counter-value bold">10 M</span>
                                        <p>TURNOVER (USD)</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div
                                        className="counter-box text-start wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.6s"
                                    >
                                        <span className="d-block counter-value bold">3700</span>
                                        <p>T / YEAR</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div
                                        className="counter-box text-start wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.9s"
                                    >
                                        <span className="d-block counter-value bold">0.5 K</span>
                                        <p>TALENTS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="position-relative">
                            <img
                                className="img-fluid wow animate__animated animate__fadeIn"
                                src={CDLAbout}
                                alt=""
                                data-wow-delay="0.3s"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="key-features d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature_box text-center">
                                <div className="f-icon"></div>
                                <div className="f-content">
                                    <h3>10 M</h3>
                                    <p>TURNOVER (USD)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature_box text-center">
                                <div className="f-icon"></div>
                                <div className="f-content">
                                    <h3>3700</h3>
                                    <p>T / YEAR</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature_box text-center">
                                <div className="f-icon"></div>
                                <div className="f-content">
                                    <h3>0.5 K</h3>
                                    <p>TALENTS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutTopSection