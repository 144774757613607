import React from 'react'

const Breadcrum = ({ title, pageName }) => {
    return (
        <div className="title-bar">
            <h1>{title}</h1>
            <p>Home  /  {pageName}</p>
        </div>
    );
}

export default Breadcrum;
