import { Drawer } from 'antd';
import { useCallback } from 'react';
import { apiUrl } from '../common/helper';
import { addProductToCart, emptyCartItems, hideCart } from '../store/slice/cart-slice';
import './component.css';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CustomDrawer = ({ showCart, handleHideCart, cartItems }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRemoveCartItem = useCallback(
        (id) => () => {
            if (window.confirm('Are you sure you want to delete this?')) {
                let token = localStorage.getItem('token');
                axios.delete(apiUrl + `my-carts/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((res) => {
                        let payload = res.data.data;
                        if (res.data.data?.cart_items.length > 0) {
                            dispatch(addProductToCart(payload));
                        }
                        else {
                            dispatch(emptyCartItems());
                        }

                        toast.success('Cart item deleted successfully', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            }

        },
        [],
    )

    const handleProductSelection = () => {
        dispatch(hideCart());
        navigate('/products-selection');
    }

    return (
        <Drawer title="MY SELECTIONS" placement="right" onClose={() => handleHideCart()} open={showCart}>
            <div className="selection_wrapper">
                {cartItems && cartItems.map((item) => (
                    <div className="item_selected d-flex" key={item.id}>
                        <div className="item_img">
                            <img src={item.product_details?.images[0]?.url} alt="Image not found" className="img-fluid" />
                        </div>
                        <div className="item_descrp">
                            <p>{item.title}</p>
                            <span className="ref">Ref. ID: {item.header_id}</span>
                        </div>
                        <i onClick={handleRemoveCartItem(item.id)} className="fa fa-times text-light"></i>
                    </div>
                ))}




                <div className="confirm position-absolute start-50 translate-middle">
                    <a onClick={handleProductSelection} className="btn from-center btn-dark border-0">Confirm your Selection</a>
                </div>
            </div>
        </Drawer>
    )
}

export default CustomDrawer;