import "./custom.css";
import Top_Navbar from './components/navbar';
import Footer from './components/footer';
import Home from "./pages/home";
import AboutUs from "./pages/about/index";
import WhatsNew from "./pages/whatsnew";
import Stories from "./pages/stories";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from "./pages/authentication/login";
import Signup from "./pages/authentication/signup";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { login, logout } from "./store/slice/auth-slice";
import axios from 'axios';
import { apiUrl } from "./common/helper";
import { toast } from 'react-toastify';
import Products from "./pages/products";
import ProductDetail from "./pages/products/product-detail";
import 'antd/dist/antd.css';
import { showCartStatus, openCart, hideCart, getCartCount, getCartStatus, getCartError, addProductToCart, getAllCartItems, emptyCartItems } from './store/slice/cart-slice';
import Drawer from "./components/drawer";
import ProductSelection from "./pages/product-selection";
import ThankYou from "./pages/product-selection/components/thank-you";
import ScrollTop from "./components/scroll-top";
import VerifyAccount from "./pages/authentication/verify-account";
import SeasonalCollection from "./pages/seasonal-collection";
import Social from "./pages/sustainablity/social";
import Environmental from "./pages/sustainablity/environmental";
import RequireAuth from "./components/require-auth";
import Loader from "./components/loader";
import { hideNavBar, showLoader, showNavBar, showNavbarStatus } from "./store/slice/basic-slice";
import ForgotPassword from "./pages/authentication/forgot-password";
import ResetPassword from "./pages/authentication/reset-password";
import NotFound from "./components/not-found";


function App() {

  const dispatch = useDispatch();
  const { isLogin, user } = useSelector((state) => state.auth);
  const loader = useSelector(showLoader);

  const isResetPassword = localStorage.getItem('password-reset-link-requested');

  const expanded = useSelector(showNavbarStatus);

  const toggleNavbar = () => {
    if (expanded) {
      dispatch(hideNavBar())
    } else {
      dispatch(showNavBar());
    }
  }


  //global cart 
  const showCart = useSelector(showCartStatus);
  const cartStatus = useSelector(getCartStatus);
  const cartError = useSelector(getCartError);
  const cartItems = useSelector(getAllCartItems);
  const cartCount = useSelector(getCartCount);

  useEffect(() => {
    let token = localStorage.getItem('token');

    const getAuthenticatedInfo = async () => {

      let payload = {
        user: user,
        token: token,
      }
      if (token) {
        await axios.get(apiUrl + 'user-details', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }).then((res) => {
          const { user } = res.data.data;
          if (res.data.success) {
            let payload = {
              user: user,
              token: token,
            }
            dispatch(login(payload));
            //dispatch(fetchCart(token));
            getAllCartItems();

          }
        }).catch(error => {
          console.log('error from app.js', error);
          dispatch(logout());
        });
      }
    }

    const getAllCartItems = async () => {
      await axios.get(apiUrl + 'my-carts', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then((res) => {
        if (res.data.success) {
          console.log('cart',res.data);
          let payload = res.data.data;
          if (res.data.data?.cart_items.length > 0) {
            dispatch(addProductToCart(payload));
          }
          else {
            dispatch(emptyCartItems());
          }

        }
      }).catch(error => {
        console.log('error from app.js loading cart', error);
      });
    }

    getAuthenticatedInfo();
  }, []);

  const handleShowCart = () => {
    dispatch(openCart());
  }

  const handleHideCart = () => {
    dispatch(hideCart());
  }

  const handleLogout = () => {
    let token = localStorage.getItem('token');
    axios.post(apiUrl + 'logout', {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      if (res.data.success) {
        dispatch(logout());
        localStorage.removeItem('token');
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }).catch(error => {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }


  return (
    <>
      <div className="App">
        <Router>
          <ScrollTop />
          < Top_Navbar
            isLogin={isLogin}
            user={user}
            handleLogout={handleLogout}
            showCart={showCart}
            handleShowCart={handleShowCart}
            cartCount={cartCount}
            expanded={expanded}
            toggleNavbar={toggleNavbar}
          />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/products-range" element={<Products />} />
            <Route path="/products-range/:id" element={<ProductDetail />} />
            <Route element={<RequireAuth />}>
              <Route path="/products-selection" element={<ProductSelection />} />
              <Route path="/products-selection/:id/thank-you" element={<ThankYou />} />
            </Route>

            <Route path="/whats-new" element={<WhatsNew />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify-account" element={<VerifyAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {isResetPassword && <Route path="/authentication/reset-password" element={<ResetPassword />} />}
            <Route path="/seasonal-collection" element={<SeasonalCollection />} />
            <Route path="/sustainablity/social" element={<Social />} />
            <Route path="/sustainablity/environmental" element={<Environmental />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <Drawer showCart={showCart} handleHideCart={handleHideCart} cartItems={cartItems} />
          {loader && <Loader />}
        </Router>

        <ToastContainer />



      </div>
    </>
  );
}

export default App;
