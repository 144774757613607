import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function herobanner() {
    return (

        <div className="slider hero-banner single-item">
            <div>
                <section className="hero-home bg-cover bg-center has-video-bg d-none">
                    <div className="video_wrapper w-100">
                        <iframe className="w-100" src="https://www.youtube.com/embed/uM99m-OU4mM?autoplay=1&mute=1&controls=0&rel=0&loop=1" title="CHANEL FW20/21 Fashion Film | Directed by VIVIENNE & TAMAS" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
                    </div>
                    <div className="container d-none z-index-20 py-5 mb-5">
                        <div className="row text-white">
                            <div className="col-lg-6">
                                <h1 className="text-uppercase mb-0 text-shadow mb-3">GNSS Receiver for Space Applications</h1>
                                <p>Accord has indigenously designed, developed, and manufactured highly reliable GPS/Global Navigation Satellite System (GNSS) receivers to Satellites.</p><a className="btn btn-light text-primary px-5" href="#!">View Details</a>
                            </div>
                        </div>
                    </div>
                </section>

                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="img/new-slide-1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="img/new-slide-2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="img/new-slide-3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="img/new-slide-4.jpg"
                            alt="Fourth slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="img/new-slide-5.jpg"
                            alt="Fifth slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </div>
        </div>
    )
}

export default herobanner