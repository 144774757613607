import Filter from "./components/filter";
import ProductSection from "./components/product-section";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { addProducts } from "../../store/slice/product-slice";
import { Pagination } from 'antd';
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { hide, show } from "../../store/slice/basic-slice";


const Products = () => {
    const product_lists = useSelector((state) => state.product.product_lists);
    const pagination = useSelector((state) => state.product.pagination_link);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParam] = useSearchParams();
    const [filters, setFilters] = useState({});
    const [isFilter, setIsFilter] = useState(false);

    const [params, setParams] = useState({
        page: 1,
        header_id: searchParams.get('header_id') || '',
        fabric_type: searchParams.get('fabric_type') || '',
        density: searchParams.get('density') || 0,
        count: 0,
        fabric_composition: searchParams.get('fabric_composition') || '',
        yarn_composition: searchParams.get('yarn_composition') || '',
        finish: searchParams.get('finish') || '',
        gauge: searchParams.get('gauge') || 0,
    });

    const headers = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${token}`
        }
    }

    useEffect(() => {
        getAllProducts();

        //check filters available
        if (params.header_id != ''
            || params.fabric_type != ''
            || params.density > 0
            || params.fabric_composition != ''
            || params.yarn_composition != ''
            || params.finish != ''
            || params.gauge > 0) {
            setIsFilter(true);
        }
        else {
            setIsFilter(false);
        }
    }, [params, window.onpopstate]);

    const onChange = pageNumber => {
        setParams({
            ...params,
            page: pageNumber
        });
        navigate({
            pathname: '/products-range',
            search: createSearchParams({ ...params, page: pageNumber }).toString()
        })
        window.scrollTo(0, 0);

    };

    const getAllProducts = async () => {
        dispatch(show());
        var all_params = new URLSearchParams(params).toString();
        await axios.get(apiUrl + `products?${all_params}`, headers)
            .then((res) => {
                dispatch(addProducts(res.data.data.products));
                setFilters(res.data.data.filters);
                dispatch(hide())
            })
            .catch((e) => {
                console.log('from products', e)
                dispatch(hide())
            });
    }

    return (
        <div className="content-wrapper">
            <section className="section-pad products_wrapper">
                <div className="container-fluid">
                    {isFilter && <div className="row">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Filters by :</li>
                                    {params.header_id != '' && <li className="breadcrumb-item">Header id / Fab desc /Fab cod : {params.header_id}</li>}
                                    {params.fabric_type != '' && <li className="breadcrumb-item">Fab Type : {params.fabric_type}</li>}
                                    {params.density > 0 && <li className="breadcrumb-item">Density : {params.density}</li>}
                                    {params.fabric_composition != '' && <li className="breadcrumb-item">Fab Composition : {params.fabric_composition}</li>}
                                    {params.yarn_composition != '' && <li className="breadcrumb-item">Yarn Composition : {params.yarn_composition}</li>}
                                    {params.finish != '' && <li className="breadcrumb-item">Finish : {params.finish}</li>}
                                    {params.gauge > 0 && <li className="breadcrumb-item">Gauge : {params.gauge}</li>}
                                </ol>
                            </nav>
                        </div>
                    </div>}

                    <div className="row">
                        <Filter params={params} setParams={setParams} filters={filters} />
                        {product_lists.length > 0 && <>
                            <ProductSection product_lists={product_lists} />
                            <div className="row text-center mb-4">
                                <div className="col-lg-12">
                                    <Pagination defaultCurrent={pagination.current_page} total={pagination.last_page} onChange={onChange} showSizeChanger={false} />
                                </div>
                            </div>
                        </>}


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Products;