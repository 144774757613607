import React, { useEffect, useState } from 'react';
import Item from './components/item';

const initialState = [
    {
        title: 'All',
        active: true,
    },
    {
        title: 'AW23-24',
        active: false,
    },
    {
        title: 'SS24',
        active: false,
    },
];

const SeasonalCollection = () => {



    const [tabs, setTabs] = useState(initialState);
    const [tabContens, setTabContents] = useState([
        [
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Knit-Shirts-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Washed-Hues-Denim-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            }
        ],
        [
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Washed-Hues-Denim-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            },
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            },
        ],
        [
            {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Knit-Shirts-1.jpg',
                download_url: '#'
            }, {
                img_url: 'https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/Laguna-Print-Yarn-Dyed-1.jpg',
                download_url: '#'
            }
        ],
    ]);
    const [tabContentActive, setTabContentActive] = useState(0);

    

    const handleTabChange = (index) => {
        tabs.map((obj, ind) => {
            let items = [...tabs];
            if (index == ind) {
                items[ind].active = true;
                setTabContentActive(index);
            }
            else {
                items[ind].active = false;
            }
            setTabs(items);
        })
        let items = [...tabs];
        items[index].active = true;
        setTabs(items);
    }

    return (
        <>
            <div className="title-bar">
                <h1>Our Stories</h1>
                <p>Be a part of our journey and get to know us better- Our inspirations, Adventures,     Products, Curators, Workforce and so much more.</p>
            </div>
            <div className="content-wrapper">
                <section className="section-pad pb-100 stories">
                    <div className="container">
                        <div className="filters filter-button-group">
                            <ul>
                                <h4>
                                    {tabs.map((tab, index) => (
                                        <li onClick={() => handleTabChange(index)} className={tab.active ? 'active' : ''} key={index}>{tab.title}</li>
                                    ))}
                                </h4>
                            </ul>
                        </div>

                        <div className="content row season-wrapper">

                            {tabContens[tabContentActive].map((item, index) => (
                                <Item key={index} img_url={item.img_url} download_url={item.download_url} />
                            ))}

                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default SeasonalCollection;