

const InputRange = ({title, min, max, value, handleChange}) => {
    return (
        <div className="input-range">
            <p className="label">{min}</p>
            <div className="range">
                <label>{title}</label>
                <div><input type="range" min={min} onChange={handleChange} onMouseDown={ handleChange } onMouseUp={ handleChange } max={max} value={value} /></div>
                <p className="selected-value">{value}</p>
            </div>
            <p className="label">{max}</p>
        </div>
    );
}

export default InputRange;