import React from 'react';
import {Container, Col} from "react-bootstrap";

function about() {
  return (
    <div className='home-sec3'>
        <section className="section-pad pb-100 about">
            <Container className="text-center">
                <Col className='position-relative abt-content text-center'>
                    <p className="mb-1 text-uppercase text-primary">About CDL </p>
                    <h2 className="lined lined-left mt-3 mb-4 bs-line-height">A world-class global player in textile and garments operations.</h2>
                    <p className=" text-muted">The Tropic Knits Group located in Mauritius is with 3,000 employees one of the biggest producers of high-quality fine knit garments in the Indian Ocean. Well-known international brands from Europe, the United States and South Africa are among the company's most important customers since many years.</p>
                    <p className=" text-muted">The Tropic Knits Group is part of the Fine Knits cluster of Ciel Textiles which is also located in Mauritius. The complete Group has more than 20,000 employees with an annual turnover of about $300 million. Once a year, Ciel Textiles hosts the Ciel Textile Chairman’s Manufacturing Excellence Award for its capital intensive companies. </p>
                    <div className="text-center">
                        <a className="btn from-center mt-1 shadow-md" href="#!">Read more</a>
                    </div>
                </Col>
            </Container>
        </section>
    </div>
  )
}

export default about