import React from 'react';
import "../../node_modules/react-slick/dist/react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import {Container} from "react-bootstrap";
import Slider from "react-slick";

function WhatsNew() {
    const settings = {
        infinite: true,
        speed: 500,
        fade: false,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        dots: true
      };
  return (
    <div className='whats-new'>
        <div className="title-bar">
            <h1>What's New!</h1>
            <p>Let's have a look at what's new in the market and all that CDL has in store for you!</p>
        </div>
        <section className="section-pad pb-100 whats-new">
            <Container>
                <div className="newbox">
                    <h2>MILES - STRETCH YOUR STYLE</h2>
                    <p>In the age of Genz's and Millennials', the hustlers and go getters, it becomes our key responsibility to propose garment solutions aiding the new lifestyle.</p>
                    <p>Our 'miles' shirt provides not just comfort but flexibility and breathability like no other. Specially sourced fabric with 20 to 30% elasticity because of its 4 way stretch property along with additional properties like wear resistance, compression and softness, stitched technically keeping in mind shirts' performance and feasibility. The fabric also provides 50+ rating in ultraviolet protection factor (UPF).</p>
                    <p>The shirt drapes around the body so perfectly that it could be your clothing choice while running a marathon.</p>
                    <p>Be a part of CDL's journey in the world of garment and textiles innovation.</p>
                    <Slider {...settings} className="new-imgs">
                        <div className="new_item">
                            <img src="https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/miles3-1.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="new_item">
                            <img src="https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/miles3.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="new_item">
                            <img src="https://showroom.laguna-clothing.com/wp-content/uploads/2021/07/miles2-1.jpg" alt="" className="img-fluid" />
                        </div>

                    </Slider>
                </div>
            </Container>
        </section>
    </div>
  )
}

export default WhatsNew