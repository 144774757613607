import Certificate1 from '../../../assets/img/certificate1.jpg';
import Certificate2 from '../../../assets/img/certificate2.jpg';
import Certificate3 from '../../../assets/img/certificate3.jpg';
import Slider from "react-slick";

const Certificates = ({settings}) => {
    return (
        <section className="certificates">
            <div className="container">
                <div className="col-md-12">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div
                                className="title_sec text-center bg-primary position-relative mb-0"
                            >
                                <h2 className="mb-0 text-uppercase text-light">Certificates</h2>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="certi">
                                <Slider {...settings}>

                                    <div
                                        className="inner wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.2s"
                                    >
                                        <img
                                            src={Certificate1}
                                            alt="Placeholder"
                                            className="img-fluid"
                                        />
                                    </div>


                                    <div
                                        className="inner wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.4s"
                                    >
                                        <img
                                            src={Certificate2}
                                            alt="Placeholder"
                                            className="img-fluid"
                                        />
                                    </div>


                                    <div
                                        className="inner wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0.6s"
                                    >
                                        <img
                                            src={Certificate3}
                                            alt="Placeholder"
                                            className="img-fluid"
                                        />
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Certificates