import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slice/auth-slice';
import basicSlice from './slice/basic-slice';
import cartSlice from './slice/cart-slice';
import productSlice from './slice/product-slice';

export default configureStore({
    reducer: {
        auth: authSlice,
        product: productSlice,
        cart: cartSlice,
        basic : basicSlice
    }
});
