const Journey = () => {
    return (
        <section className="section-pad pb-100 bg-light timeline">
            <div className="container">
                <div
                    className="title_sec text-center position-relative pb-3 mb-5 mx-auto"
                >
                    <h2 className="mb-0 text-uppercase">Our Journey</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-4 p-0">
                        <div className="time_box odd">
                            <span className="shape"></span>
                            <span
                                className="date wow animate__animated animate__fadeInUp"

                            >1989</span>
                            <p
                                className="wow animate__animated animate__fadeInUp"

                            >
                                Created in 1989. CDL is part of the Knits Cluster of Ciel
                                Textile.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="time_box">
                            <span className="shape"></span>
                            <span
                                className="date wow animate__animated animate__fadeInUp"

                            >2006</span>
                            <p
                                className="wow animate__animated animate__fadeInUp"

                            >
                                Consolidation in 2006 of one sister company (Tropic Knits).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Journey