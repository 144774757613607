import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { apiUrl } from "../../../common/helper";


const ThankYou = () => {
    const [pdfLink, setPdfLink] = useState('');

    const { id } = useParams();
    const token = useSelector((state) => state.auth.token);
    useEffect(() => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        const getEnquiryDetail = async () => {
            await axios.get(apiUrl + `enquiries/${id}`, headers)
                .then((res) => {
                    setPdfLink(res.data.data.pdf_link);
                })
                .catch((e) => {
                    console.log('from thank you error', e)
                });
        }

        getEnquiryDetail();
    }, [])
    return (
        <>
            <div className="title-bar">
                <h1>My Selections</h1>
            </div>
            <div className="content-wrapper">
                <section className="section-pad pb-100 whats-new">
                    <div className="container">
                        <div className="thanks text-center">
                            <h2 className="fw-bold mb-5">Thank you for visiting CDL Showroom</h2>
                            <p>Your selections are sent to your registered email ID. </p>
                            <Link to="/" className="btn from-center mt-2 me-md-3 btn-dark border-0">Back to homepage</Link>
                            <a href={pdfLink} target="_blank" className="btn from-center mt-2 ms-md-3 shadow-md"><i className="fas fa-file-pdf"></i> Download Selections</a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ThankYou;