import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getAllCartItems, emptyCartItems } from "../../store/slice/cart-slice";
import SelectionItem from "./components/selection-item"
import { toast } from 'react-toastify';
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { useNavigate } from "react-router-dom";
import { hide, show } from "../../store/slice/basic-slice";


const ProductSelection = () => {
    const cartItems = useSelector(getAllCartItems);

    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleEnquiry = () => {
        dispatch(show())
        let token = localStorage.getItem('token');

        let payload = {
            message,
        }
        axios.post(apiUrl + 'enquiries', payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                toast.success('Enquiry submitted successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(emptyCartItems());
                dispatch(hide())
                navigate('/products-selection/' + res.data.data.enquiry.id + '/thank-you');
            })
            .catch((e) => {
                toast.error(e.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(hide())
            })
    }

    return (
        <>
            <div className="title-bar">
                <h1>My Selections</h1>
            </div>

            <div className="content-wrapper">
                <section className="section-pad pb-100 whats-new">
                    <div className="container">

                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>PRODUCT</th>
                                        <th>COMMENTS</th>
                                        <th>REMOVE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems && cartItems.map((item) => (<SelectionItem key={item.id} item={item} />))}
                                </tbody>
                            </table>
                        </div>
                        <form action="#" className="add-info">
                            <h4>Additional Information:</h4>
                            <textarea className="w-100" name="more-info" id="" rows="7" placeholder="Write your message..." onChange={(e) => setMessage(e.target.value)}></textarea>
                        </form>
                        <div className="text-end">
                            <a onClick={handleEnquiry} className="btn from-center btn-dark border-0">Submit your Selection</a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ProductSelection

