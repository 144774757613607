import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { apiUrl } from "../../../common/helper";
import axios from "axios";
import { addProductToCart } from "../../../store/slice/cart-slice";
import { hide, show } from "../../../store/slice/basic-slice";

const SelectionItem = ({ item }) => {

    const dispatch = useDispatch();

    const handleRemoveCartItem = useCallback(
        (id) => () => {
            if (window.confirm('Are you sure you want to delete this?')) {
                dispatch(show());
                let token = localStorage.getItem('token');
                axios.delete(apiUrl + `my-carts/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((res) => {
                        let payload = res.data.data;
                        dispatch(addProductToCart(payload));
                        toast.success('Cart item deleted successfully', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        dispatch(hide());
                    })
                    .catch((e) => {
                        console.log(e);
                        dispatch(hide());
                    })
            }

        },
        [],
    )

    return (
        <tr>
            <td>
                <div className="d-flex column-1">
                    <div className="table-img">
                        <img src={item.product_details?.images[0]?.url} alt="Image not found" className="img-fluid" />
                    </div>
                    <div className="prd-desc">
                        <h5>{item.title}</h5>
                        <span className="ref">Reference ID: {item.header_id}</span>
                        <p><b>Type:</b> {item.fab_type}</p>
                        <p><b>Density:</b> {item.fab_density}</p>
                        <p><b>Gauge:</b> {item.gauge}</p>
                    </div>
                </div>
            </td>
            <td>
                <p className="text-center">{item.comments}</p>
            </td>
            <td>
                <a onClick={handleRemoveCartItem(item.id)}>Remove <i className="fa fa-times"></i></a>
            </td>
        </tr>
    )
}

export default SelectionItem;