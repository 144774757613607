import CDLVision from '../../../assets/img/CDL-vision.png';
import VMVLogo from '../../../assets/img/vmv.jpg';

const VMPWrapper = () => {
    return (
        <section className="vmv-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="vmv-box text-white">
                            <img
                                src={CDLVision}
                                width="60px"
                                alt=""
                                className="img-fluid mb-3"
                            />
                            <h3 className="text-white">Vision</h3>
                            <p>Be the best global fashion partner</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <img src={VMVLogo} alt="" className="img-fluid fill" />
                    </div>
                    <div className="col-md-3">
                        <div className="vmv-box text-white">
                            <h3 className="text-white">Mission</h3>
                            <ul>
                                <li>Improve fashion everyday</li>
                                <li>Exceptional Design</li>
                                <li>Outstanding Service and Quality</li>
                                <li>Agility and Speed</li>
                                <li>Sustainability Leadership</li>
                                <li>Transformational Solutions</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="vmv-box text-white">
                            <h3 className="text-white">Values</h3>
                            <ul>
                                <li>Passionate</li>
                                <li>Excellence at Core</li>
                                <li>Agile and Creative</li>
                                <li>Our People Our Gold</li>
                                <li>Trust and Funs</li>
                                <li>Winning Well</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VMPWrapper