
const OurAchievement = () => {
    return (
        <section className="section-pad awards">
            <div className="container">
                <div
                    className="title_sec text-center position-relative pb-3 mb-5 mx-auto"
                >
                    <h2 className="mb-0 text-uppercase">Our Achievements</h2>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div
                            className="awards_box wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="achievement">
                                <span className="bold award_year">2015</span>
                                <h2>Manufacturing Excellence Award</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            className="awards_box wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="achievement">
                                <span className="bold award_year">2016</span>
                                <h2>Manufacturing Excellence Award</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            className="awards_box wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s"
                        >
                            <div className="achievement">
                                <span className="bold award_year">2017</span>
                                <h2>World Class Innovation Award</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div
                            className="awards_box wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.9s"
                        >
                            <div className="achievement">
                                <span className="bold award_year">2017</span>
                                <h2>
                                    CSR<br />
                                    Award
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurAchievement