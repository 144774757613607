import React from 'react';
import { Link } from 'react-router-dom';

function SideContents({ title, btnName, btnUrl }) {
    return (
        <div className="position-relative register_box">
            <h3 className="bold text-uppercase">{title}</h3>
            <p><strong>New Customer?</strong>Create an account with us to :</p>
            <ul style={{ marginLeft: '15px', marginTop: '10px', lineHeight: '26px' }}>
                <li>Check our entire shirts’ range</li>
                <li>Access new developments &amp; design collections</li>
                <li>Read our product stories</li>
                <li>Make your selections and raise queries</li>
                <li>Save your selections and download</li>
            </ul>
            <Link to={btnUrl} className="btn from-center mt-3">{btnName}</Link>
        </div>
    )
}

export default SideContents