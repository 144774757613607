import React, { useEffect, useState } from 'react';
import Breadcrum from '../../common/breadcrum';
import SideContents from './components/side-contents';
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { Country, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import { hide, show, showVisibleStatus } from '../../store/slice/basic-slice';

const initialState = {
    'first_name': '',
    'last_name': '',
    'email': '',
    'password': '',
    'country': '',
    'city': '',
    'company_email': '',
    'phone': '',
}



const Signup = () => {

    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const btnClicked = useSelector(showVisibleStatus);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const handleCountryChange = (e) => {
        setState({
            ...state,
            country: e.target.value,
        })
        setCities(City.getCitiesOfCountry(e.target.value));
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        dispatch(show())
        axios.post(apiUrl + 'register', state, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(hide())
            navigate("/login");
        }).catch(e => {
            toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(hide())
        });
    }

    return (
        <>
            <Breadcrum title={'My Account'} pageName={'My Account'} />

            <div className="content-wrapper">
                <section className="section-pad pb-100 login_Wrapper">
                    <div className="container">
                        <div className="col-md-10 m-auto">
                            <div className="row align-items-start">
                                <div className="col-md-6">
                                    <div className="login-form pe-md-5">
                                        <h3 className="bold">REGISTER</h3>
                                        <form onSubmit={handleFormSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">First name</label>
                                                        <input type="text" onChange={handleChange} name="first_name" className="form-control" aria-describedby="firstname" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last name</label>
                                                        <input type="text" onChange={handleChange} name="last_name" className="form-control" aria-describedby="lastname" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Country</label>
                                                        <select onChange={handleCountryChange} name="country" className="form-control">
                                                            <option value=''>Select country</option>
                                                            {countries.map((country, index) => (
                                                                <option key={index} value={country.isoCode}>{country.name}</option>
                                                            ))}
                                                        </select>
                                                        {/* <input type="text" onChange={handleChange} name="country" className="form-control" aria-describedby="Country" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">City</label>
                                                        <select onChange={handleChange} name="city" className="form-control">
                                                            <option value=''>Select city</option>
                                                            {cities.map((city, index) => (
                                                                <option key={index} value={city.name}>{city.name}</option>
                                                            ))}
                                                        </select>
                                                        {/* <input type="text" onChange={handleChange} name="city" className="form-control" aria-describedby="city" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Company phone</label>
                                                <input type="text" onChange={handleChange} name="phone" className="form-control" aria-describedby="phone" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Company Email</label>
                                                <input type="email" onChange={handleChange} name="company_email" className="form-control" aria-describedby="emailHelp" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input type="password" onChange={handleChange} name="password" className="form-control" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email address</label>
                                                <input type="email" onChange={handleChange} name="email" className="form-control" aria-describedby="emailHelp" />
                                            </div>
                                            <div className="mb-3">
                                                <small>A password will be sent to your email address.<br /><br /> Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a>privacy policy.</a></small>
                                            </div>
                                            <button type="submit" className="btn from-center btn-dark border-0 w-100" disabled={btnClicked}>REGISTER</button>
                                        </form>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <SideContents title={'LOGIN'} btnName={'LOGIN'} btnUrl={'/login'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Signup;