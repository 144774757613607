import React, { useEffect, useState } from 'react';
import Breadcrum from '../../common/breadcrum';
import SideContents from './components/side-contents';
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { toast } from 'react-toastify';
import { login } from '../../store/slice/auth-slice';
import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToCart } from '../../store/slice/cart-slice';
import { hide, show, showVisibleStatus } from '../../store/slice/basic-slice';


const Login = () => {

    let navigate = useNavigate();
    let location = useLocation();

    const from = location.state?.from?.pathname || "/";

    const dispatch = useDispatch();
    const [verified, setVerified] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const btnClicked = useSelector(showVisibleStatus);

    useEffect(() => {
        let id = searchParams.get('id');
        if (id) {
            setVerified(true);
        }
    }, []);

    const [state, setState] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(show());
        axios.post(apiUrl + 'login', state, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            const { success, message } = res.data;
            const { user, token } = res.data.data;
            if (success) {
                localStorage.setItem('token', token);
                let payload = {
                    user: user,
                    token: token,
                }
                dispatch(login(payload));
                if (res.data.data.cart) {
                    dispatch(addProductToCart(res.data.data.cart));
                }
                //dispatch(fetchCart(token));
                dispatch(hide());
                navigate(from, { replace: true });
            }
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(e => {
            toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(hide());
            if (e.response.status == 400) {
                navigate('/verify-account');
            }
        });

    }

    return (
        <>
            <Breadcrum title={'My Account'} pageName={'My Account'} />
            <div className="content-wrapper">
                <section className="section-pad pb-100 login_Wrapper">
                    <div className="container">
                        <div className="col-md-10 m-auto">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="login-form pe-md-5">
                                        {verified && <div className='verified-mesage'>
                                            <p>Account verified successfully</p>
                                        </div>}
                                        <h3 className="bold">LOGIN</h3>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Email address</label>
                                                <input type="email" onChange={handleChange} name="email" className="form-control" aria-describedby="emailHelp" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input type="password" onChange={handleChange} name="password" className="form-control" />
                                            </div>
                                            <div className="mb-3 form-check">
                                                <input type="checkbox" className="form-check-input" />
                                                <label className="form-check-label" >Remember me</label>
                                                <p style={{ float: 'right' }}>
                                                    <Link to="/forgot-password">Forgot password?</Link>
                                                </p>
                                            </div>
                                            <button type="submit" className="btn from-center btn-dark border-0 w-100" disabled={btnClicked}>LOG IN</button>
                                        </form>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <SideContents title={'REGISTER'} btnName={'REGISTER'} btnUrl={'/signup'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Login;