import React, { useState } from 'react';
import Breadcrum from '../../common/breadcrum';
import SideContents from './components/side-contents';
import axios from 'axios';
import { apiUrl } from '../../common/helper';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { hide, show, showVisibleStatus } from '../../store/slice/basic-slice';

const ForgotPassword = () => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const btnClicked = useSelector(showVisibleStatus);

    const hideMessage = () => {
        setTimeout(() => {
            setShowMessage(false);
        }, 5000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(show())
        axios.post(apiUrl + 'reset-password', { email }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setShowMessage(true);
            setMessage(res.data.message);
            setColor('#00ff37');
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(hide());
            hideMessage();
            localStorage.setItem('password-reset-link-requested', 'true');
        }).catch(e => {
            setShowMessage(true);
            setMessage(e.response.data.message);
            setColor('red');
            toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            hideMessage();
            dispatch(hide())
        });
    }


    return (
        <>
            <Breadcrum title={'Forgot Password'} pageName={'forgot-password'} />
            <div className="content-wrapper">
                <section className="section-pad pb-100 login_Wrapper">
                    <div className="container">
                        <div className="col-md-10 m-auto">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="login-form pe-md-5">

                                        <h3 className="bold">Please enter your email id</h3>



                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Email address</label>
                                                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} className="form-control" aria-describedby="emailHelp" />
                                            </div>
                                            {showMessage && <div className="mb-3">
                                                <p style={{ color: color, fontSize: '18px' }}>{message}</p>
                                            </div>}
                                            <button type="submit" className="btn from-center btn-dark border-0 w-100" disabled={btnClicked}>Send Password Reset Link</button>
                                        </form>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <SideContents title={'LOGIN'} btnName={'LOGIN'} btnUrl={'/login'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ForgotPassword;