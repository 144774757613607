import React from 'react';
import {Container, Row, Col} from "react-bootstrap";


function footer() {
  return (
    <div className='footer_sec'>
        <footer className="footer bg-inv">
            <Container className="pt-5">
                <Row className="gy-4">
                <Col md={6} lg={3} className="contact-info">
                    <img src="img/CDL-Charcoal.png" alt="" className="img-fluid w-50 mb-4" />
                    <h5 className="text-primary">Address</h5>
                    <p className="text-dark d-flex ">	<i className="fas fa-map-marker-alt"></i>Royal Road, Forest Side, Mauritius</p>
                    <h5 className="text-primary mt-4">Write to us</h5>
                    <p className="text-dark d-flex mb-1">	<i className="fas fa-envelope"></i><a className="text-dark" href="mailto:showroom@cdl-fabrics.com">showroom@cdl-fabrics.com</a></p>
                    <p className="text-dark d-flex ">	<i className="fas fa-envelope"></i><a className="text-dark" href="mailto:marketing@cdl-fabrics.com">marketing@cdl-fabrics.com</a></p>
                </Col>
                <Col md={6} lg={4} className="contact-info">
                    <h5 className="text-primary lined mb-4">Say Hello!</h5>
                    <h4 className="text-dark h6">Mr Nishaan Dookhan - <span className="text-primary">Director</span></h4>
                    <p className="text-dark d-flex mb-1"><i className="fas fa-envelope"></i> <a className="text-dark" href="mailto:NDookhan@tropicknits.com">NDookhan@tropicknits.com</a></p>
                    <p className="text-dark d-flex mb-4"><i className="fas fa-mobile-alt"></i> <a className="text-dark" href="tel:23057289598">230 5728 9598</a></p>
                    
                    
                    <h4 className="text-dark h6">Mr Yan Bradshaw - <span className="text-primary">Marketing Manager</span></h4>
                    <p className="text-dark d-flex mb-1"><i className="fas fa-envelope"></i> <a className="text-dark" href="mailto:YBradshaw@tropicknits.com">YBradshaw@tropicknits.com</a></p>
                    <p className="text-dark d-flex mb-1"><i className="fas fa-mobile-alt"></i><a className="text-dark" href="tel:23059384658">230 5938 4658</a></p>
                </Col>
                
                <Col md={6} lg={2} className="contact-info">
                    <h2 className="h5 text-primary lined mb-4">Quick Links</h2>
                    <div className="d-flex">
                    <ul className="list-unstyled d-inline-block mb-0">
                        <li className="mb-2"><a className="footer-link" href="#">About Us </a></li>
                        <li className="mb-2"><a className="footer-link" href="#">Our Range </a></li>
                        <li className="mb-2"><a className="footer-link" href="#">What's New</a></li>
                        <li className="mb-2"><a className="footer-link" href="#">Seasonal Collection </a></li>
                        <li className="mb-2"><a className="footer-link" href="#">Stories </a></li>
                        <li className="mb-2"><a className="footer-link" href="#">Sustainability </a></li>
                    </ul>
                    </div>
                </Col>
                
                <Col md={6} lg={3}>
                    <h2 className="h5 text-primary lined mb-4">Join Us</h2>
                    <h4 className="text-dark h6">Subscribe & Receive Exclusive</h4>

                    <form action="#" className="newsletter">
                        <div className="input-group">
                        <input className="form-control" type="email" placeholder="Enter your mail ID"/>
                        <button className="btn from-center" type="submit"> <i className="fas fa-paper-plane"></i></button>
                        </div>
                    </form>
                </Col>
                </Row>
            </Container>
            <div className="copyrights bg-primary">       
                <Container className="text-center py-4">
                <p className="mb-0 text-light text-sm">&copy; Copyrights 2022, CDL. All Rights Reserved. Designed by <a className="text-creamy" href="https://webomindapps.com" target="_blank">Webomindapps</a>.</p>
                </Container>
            </div>
            </footer>
    </div>
  )
}

export default footer