import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLogin: false,
        user: null,
        token: null,
    },
    reducers: {
        login(state, action) {
            state.isLogin = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout(state, action) {
            state.isLogin = false;
            state.user = null;
            state.token = null;
        }
    }
});


export const showLoginStatus = (state) => state.auth.isLogin;
export const showLoginToken = (state) => state.auth.token;

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;