import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown, Button, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from '../assets/img/CDL-White.png';
import TexttileLogo from '../assets/img/Ciel-textile-logo.png';

const top_navbar = ({ isLogin, user, handleLogout, showCart, handleShowCart, cartCount, expanded, toggleNavbar }) => {

    return (
        <>
            <div className='home-sec1'>
                <header className="header">
                    <Navbar expand="lg" variant="dark" className="py-0 shadow-sm" expanded={expanded}>
                        <Container fluid>
                            <Link to="/" className="navbar-brand"><img src={Logo} alt="" width="140" /></Link>
                            {/* <Navbar.Brand href="/"><img src="img/CDL-White.png" alt="" width="140"/></Navbar.Brand> */}
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => toggleNavbar()} />
                            <Navbar.Collapse id="basic-navbar-nav" className="py-3 py-lg-0">
                                <Nav className="mx-auto">
                                    <Nav.Item>
                                        <Link to="/about" className="letter-spacing-01 nav-link">About us </Link>
                                    </Nav.Item>

                                    {/* {isLogin && <Nav.Item>
                                        <Link to="/products-range" className="letter-spacing-01 nav-link">Our Range</Link>
                                    </Nav.Item>} */}

                                    {/* <Link to="/products-range" className="letter-spacing-01 nav-link">Our Range</Link> */}

                                    <a href='/products-range' className="letter-spacing-01 nav-link">Our Range</a>

                                    <Nav.Item>
                                        <Link to="/whats-new" className="letter-spacing-01 nav-link">Whats's New</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Link to="/seasonal-collection" className="letter-spacing-01 nav-link">Seasonal Collections</Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Link to="/stories" className="letter-spacing-01 nav-link">Stories</Link>
                                    </Nav.Item>
                                    <NavDropdown title="Sustainability" id="basic-nav-dropdown">
                                        <NavDropdown.Item>
                                            <Link className='dropdown-item' to="/sustainablity/social">Social</Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link className='dropdown-item' to="/sustainablity/environmental">Environmental</Link>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                                <Nav className="ms-auto align-items-center" as="ul">
                                    {isLogin ? <NavDropdown title={user.first_name + ' ' + user.last_name} id="basic-nav-dropdown">
                                        <a className='dropdown-item' onClick={() => handleLogout()}>Logout</a>
                                    </NavDropdown> : <Nav.Item>
                                        <Link to="/login" className="letter-spacing-01 nav-link"> Login / Register </Link>
                                    </Nav.Item>}

                                    {/* <Nav.Item as="li" className="px-2">
                                        <Nav.Link href="#link" className="px-1 d-inline-block" id="searchToggler">
                                            <i className="fas fa-search "></i>
                                            <i className="fas fa-times d-none"></i>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    {isLogin && <Nav.Item as="li" className="px-2">
                                        <Nav.Link onClick={() => handleShowCart()} className="px-1 d-inline-block position-relative" id="shop-cart">
                                            <i className="fas fa-shopping-bag"></i>
                                            <span className="count bg-creamy">{cartCount}</span>
                                        </Nav.Link>
                                    </Nav.Item>}

                                    <Navbar.Brand className="me-2 ms-5" href="https://www.cieltextile.com/" target="_new" rel="noreferrer">
                                        <img src={TexttileLogo} alt="" width="140" />
                                    </Navbar.Brand>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <div className="p-3  shadow-sm index-forward border-top" id="search">
                        <Container>
                            <form action="#">
                                <div className="input-group">
                                    <input className="form-control" type="search" placeholder="What're you looking for..." />
                                    <Button className="btn from-center" type="submit"> <i className="fas fa-search me-2"></i>Search</Button>
                                </div>
                            </form>
                        </Container>
                    </div>


                </header>



            </div>

        </>
    )
}


export default top_navbar;