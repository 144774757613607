import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

function Stories() {
  return (
    <div className='content-wrapper'>
        <div className="title-bar">
            <h1>Our Stories</h1>
            <p>Be a part of our journey and get to know us better- Our inspirations, Adventures, Products, Curators, Workforce and so much more.</p>
        </div>
        <section className="section-pad pb-100 stories">
            <Container>
                <Col md={8} className="mx-auto">
                    <div className="blog-item wow slideInUp" data-wow-delay="0.1s">
                        <div className="position-relative">
                           <a href="#" className="s-img-wrapper">
                            <img className="img-fluid w-100 rounded-top" src="https://showroom.laguna-clothing.com/wp-content/uploads/2017/06/Laguna-X-Soumita-Dass.jpg" alt=""/>
                           </a>
                            <div className="blog-date">
                                <h5 className="text-white fw-bold mb-0">01</h5>
                                <h6 className="text-light mb-0">January</h6>
                            </div>
                        </div>
                        <div className="bg-light rounded-bottom px-5 py-4">
                           <Row className="align-items-center">
                               <Col md={8} >
                                   <div className="d-flex mb-3">
                                        <span className="text-primary text-uppercase">Admin</span>
                                        <span className="text-primary px-2">|</span>
                                        <span className="text-primary text-uppercase">CDL Stories</span>
                                    </div>
                                    <a className="h3 m-0 text-primary" href="">Finding Solution For the Disabled</a>
                               </Col>
                               <Col md={4} className="text-end">
                                   <a className="btn from-center mt-1 shadow-md" href="#!">Read more</a>
                               </Col>
                           </Row>
                            
                        </div>
                    </div>
                </Col>
            </Container>
        </section>
    </div>
  )
}

export default Stories