import { useState, useMemo } from "react";
import InputRange from "../../../components/input-range";
import _debounce from 'lodash/debounce';
import { Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { createSearchParams, useNavigate } from "react-router-dom";



const callFilterApi = (inputValue, type, params, setParams, navigate) => {
    setParams({
        ...params,
        page: 1,
        [type]: inputValue
    });
    navigate({
        pathname: '/products-range',
        search: createSearchParams({ ...params, [type]: inputValue }).toString()
    })
}

const Filter = ({ params, setParams, filters }) => {
    const navigate = useNavigate();
    const { Panel } = Collapse;

    const [headerId, setHeaderId] = useState(params.header_id)
    const [density, setDensity] = useState({
        min: 0,
        max: 1000,
        value: parseFloat(params.density)
    });
    const [gauge, setGauge] = useState({
        min: 0,
        max: 100,
        value: params.gauge
    });

    const [fabricType, setFabricType] = useState([]);
    const [fabricComp, setFabricComp] = useState([]);
    const [yarnComp, setYarnComp] = useState([]);
    const [finish, setFinish] = useState(params.finish);

    const [callDebounceAPi] = useState(() => _debounce(callFilterApi, 500));

    useMemo(() => {
        if (params.fabric_type != '') {
            params.fabric_type.split(",").map((item) => {
                if (item != '' && !fabricType.includes(item)) {
                    fabricType.push(item)
                }
            })
        }
    }, [params.fabric_type])

    useMemo(() => {
        if (params.fabric_composition != '') {
            params.fabric_composition.split(",").map((item) => {
                if (item != '' && !fabricComp.includes(item)) {
                    fabricComp.push(item)
                }
            })
        }
    }, [params.fabric_composition])

    useMemo(() => {
        if (params.yarn_composition != '') {
            params.yarn_composition.split(",").map((item) => {
                if (item != '' && !yarnComp.includes(item)) {
                    yarnComp.push(item)
                }
            })
        }
    }, [params.yarn_composition])



    const handleDensityChange = (e) => {
        setDensity({
            ...density,
            value: e.target.value,
        });
        callDebounceAPi(e.target.value, 'density', params, setParams, navigate);
    }

    const handleGaugeChange = (e) => {
        setGauge({
            ...gauge,
            value: e.target.value,
        });
        callDebounceAPi(e.target.value, 'gauge', params, setParams, navigate);
    }

    const handleHeaderId = (e) => {
        setHeaderId(e.target.value);
        callDebounceAPi(e.target.value, 'header_id', params, setParams, navigate);
    }

    const handleFinish = (e) => {
        setFinish(e.target.value);
        callDebounceAPi(e.target.value, 'finish', params, setParams, navigate);
    }

    const handleFabType = (fab_type) => {
        if (fabricType.includes(fab_type)) {
            const index = fabricType.indexOf(fab_type);
            if (index > -1) {
                fabricType.splice(index, 1);
            }
        }
        else {
            fabricType.push(fab_type);
        }
        updateFilters('fabric_type', fabricType);
    }

    const handleFabComp = (fab_comp) => {
        if (fabricComp.includes(fab_comp)) {
            const index = fabricComp.indexOf(fab_comp);
            if (index > -1) {
                fabricComp.splice(index, 1);
            }
        }
        else {
            fabricComp.push(fab_comp);
        }
        updateFilters('fabric_composition', fabricComp);
    }

    const handleYarnComp = (yarn_comp) => {
        if (yarnComp.includes(yarn_comp)) {
            const index = yarnComp.indexOf(yarn_comp);
            if (index > -1) {
                yarnComp.splice(index, 1);
            }
        }
        else {
            yarnComp.push(yarn_comp);
        }
        updateFilters('yarn_composition', yarnComp);
    }

    const updateFilters = (type, state) => {
        let value = '';
        state.map((item) => {
            value += item + ','
        });
        callDebounceAPi(value, type, params, setParams, navigate);
    }

    const onChange = (key) => {

    };

    return (
        <div className="col-lg-3 col-md-3 col-12 sidebar-left">
            <div className="cdl-filters">
                <h5 className="widget-title text-uppercase">Filters</h5>
                <div className="mb-2">
                    <input type="text"
                        placeholder="search by header id or fab desc"
                        className="form-control"
                        value={headerId}
                        onChange={handleHeaderId}
                    />
                </div>

                <Collapse onChange={onChange}>
                    <Panel header="Fabric Type" key="1">
                        {filters.fab_type && filters.fab_type.map((fab_type, index) => (
                            <div
                                onClick={() => handleFabType(fab_type['fab_type'])}
                                className={`filter-name ${fabricType.includes(fab_type['fab_type']) ? 'selected' : ''}`}
                                key={index}>
                                {fab_type['fab_type']}
                            </div>
                        ))}
                    </Panel>
                    <Panel header="Density" key="2">
                        <InputRange title='Density' min={density.min} max={density.max} value={density.value} handleChange={handleDensityChange} />
                    </Panel>
                    {/* <Panel header="Count" key="3">
                        <InputRange title='Fad Count' min={density.min} max={density.max} value={density.value} handleChange={handleDensityChange} />
                    </Panel> */}
                    <Panel header="Fabric Composition" key="4">
                        {filters.fab_comp && filters.fab_comp.map((fab_comp, index) => (
                            <div
                                onClick={() => handleFabComp(fab_comp['description'])}
                                className={`filter-name ${fabricComp.includes(fab_comp['description']) ? 'selected' : ''}`}
                                key={index}>
                                {fab_comp['description']}
                            </div>
                        ))}
                    </Panel>
                    <Panel header="Yarn Composition" key="5">
                        {filters.yarn_comp && filters.yarn_comp.map((yarn_comp, index) => (
                            <div
                                onClick={() => handleYarnComp(yarn_comp['description'])}
                                className={`filter-name ${yarnComp.includes(yarn_comp['description']) ? 'selected' : ''}`}
                                key={index}>
                                {yarn_comp['description']}
                            </div>
                        ))}
                    </Panel>
                    <Panel header="Finish" key="6">
                        <div className="d-flex">
                            <input type="text" className="form-control" value={finish} onChange={handleFinish} placeholder="Search here..." />
                            <SearchOutlined style={{ fontSize: 25, margin: 10 }} />
                        </div>
                    </Panel>
                    <Panel header="Gauge" key="7">
                        <InputRange title='Gauge' min={gauge.min} max={gauge.max} value={gauge.value} handleChange={handleGaugeChange} />
                    </Panel>
                </Collapse>

                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                    </div>
                    <div className="accordion-item">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;