import React from 'react';
import "../../node_modules/react-slick/dist/react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import Slider from "react-slick";


function products() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    centerMode: true,
    centerPadding: 0,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1
  };
  return (
    <section className="products-section">
      <Container fluid className="text-center">
        <div className="title_sec">
          <h2 className="text-uppercase text-white">Our Products</h2>
          <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est perspiciatis facere dolor ex esse nemo laudantium dolorum id, quo numquam qui. Laborum at ipsa maiores, debitis quam, fugiat explicabo veniam!</p>
        </div>
        <Slider {...settings} className="products">
          <div className="prod_wrapper">
            <div className="card position-relative">
              <div className="card-body p-0 shadow-sm">
                <div className="position-relative">
                  <img className="img-fluid" src="img/cdl-product-1.jpg" alt="..." />
                  <div className="product-overlay"></div>
                </div>
                <div className="p-3 p_contents">
                  <h3 className="mb-2">Product Name-1</h3>
                  <a className="btn from-center" href="#!">View Details</a>
                </div>
              </div>
            </div>
          </div>
          <div className="prod_wrapper">
            <div className="card position-relative">
              <div className="card-body p-0 shadow-sm">
                <div className="position-relative">
                  <img className="img-fluid" src="img/cdl-product-2.jpg" alt="..." />
                  <div className="product-overlay"></div>
                </div>
                <div className="p-3 p_contents">
                  <h3 className="mb-2">Product Name-2</h3>
                  <a className="btn from-center" href="#!">View Details</a>
                </div>
              </div>
            </div>
          </div>
          <div className="prod_wrapper">
            <div className="card position-relative">
              <div className="card-body p-0 shadow-sm">
                <div className="position-relative">
                  <img className="img-fluid" src="img/cdl-product-3.jpg" alt="..." />
                  <div className="product-overlay"></div>
                </div>
                <div className="p-3 p_contents">
                  <h3 className="mb-2">Product Name-3</h3>
                  <a className="btn from-center" href="#!">View Details</a>
                </div>
              </div>
            </div>
          </div>
          <div className="prod_wrapper">
            <div className="card position-relative">
              <div className="card-body p-0 shadow-sm">
                <div className="position-relative">
                  <img className="img-fluid" src="img/cdl-product-4.jpg" alt="..." />
                  <div className="product-overlay"></div>
                </div>
                <div className="p-3 p_contents">
                  <h3 className="mb-2">Product Name-4</h3>
                  <a className="btn from-center" href="#!">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </Container>
    </section>
  )
}

export default products