import React from "react";
import ProductItem from "./product-item";


const ProductSection = ({ product_lists }) => {
    return (
        <div className="col-lg-9 col-md-9 col-12 all_products">
            <div className="range-bg position-relative">
                <img src="img/range-bg-1.webp" alt="" className="img-fluid" />
                <div className="headings position-absolute top-50 start-50 translate-middle">
                    <div className="title-bar bg-transparent">
                        <h1 className="text-light">Product Range</h1>
                        <p className="text-light">Be a part of our journey and get to know us better- Our inspirations, Adventures, Products, Curators, Workforce and so much more.</p>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    {product_lists.map((item) => (
                        <ProductItem item={item} key={item.id} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProductSection;