import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

function blogs() {
  return (
    <div className='home-sec4'>
        <Container fluid className="latest-articles pb-5">
            <Container>
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                    <h1 className="mb-0">Latest Articles On Sustainability</h1>
                </div>
                <Row className="g-5">
                    <Col lg={4}>
                        <div className="blog-item bg-light rounded overflow-hidden">
                            <div className="blog-img position-relative overflow-hidden">
                                <img className="img-fluid" src="img/blog-3.jpg" alt="..."/>
                                <a className="position-absolute bottom-0 start-0 bg-primary text-white rounded-end mb-5 py-2 px-4" href="">Sustainability</a>
                            </div>
                            <div className="p-4">
                                <div className="d-flex mb-3">
                                    <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                    <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                                </div>
                                <h4 className="mb-3">Article Title-1</h4>
                                <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                <a className="text-uppercase btn from-center shadow-sm" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="blog-item bg-light rounded overflow-hidden">
                            <div className="blog-img position-relative overflow-hidden">
                                <img className="img-fluid" src="img/blog-2.jpg" alt="..."/>
                                <a className="position-absolute bottom-0 start-0 bg-primary text-white rounded-end mb-5 py-2 px-4" href="">Manufacturing</a>
                            </div>
                            <div className="p-4">
                                <div className="d-flex mb-3">
                                    <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                    <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                                </div>
                                <h4 className="mb-3">Article Title-2</h4>
                                <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                <a className="text-uppercase btn from-center shadow-sm" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="blog-item bg-light rounded overflow-hidden">
                            <div className="blog-img position-relative overflow-hidden">
                                <img className="img-fluid" src="img/blog-3.jpg" alt="..."/>
                                <a className="position-absolute bottom-0 start-0 bg-primary text-white rounded-end mb-5 py-2 px-4" href="">Events</a>
                            </div>
                            <div className="p-4">
                                <div className="d-flex mb-3">
                                    <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                    <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                                </div>
                                <h4 className="mb-3">Article Title-3</h4>
                                <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                <a className="text-uppercase btn from-center shadow-sm" href="">Read More <i className="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>
  )
}

export default blogs