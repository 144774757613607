import React from 'react';
import "../../node_modules/react-slick/dist/react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import {Container} from "react-bootstrap";
import Slider from "react-slick";

function customers() {
    const settings = {
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false
      };
  return (
    <div className='home-sec5'>
        <section className="">
            <Container fluid>
                <Slider {...settings} className="customer-logo marquee">
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/ck.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/asos.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/j-crew.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/lacoste.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/levis.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/puma.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                    <div className="slick-slide">
                        <div className="inner">
                        <img src="img/customers/suitsupply.jpg" alt="Placeholder" width="179" height="78"/>
                        </div>
                    </div>
                </Slider>
            </Container>
        </section>
    </div>
  )
}

export default customers