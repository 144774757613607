import { createSlice } from "@reduxjs/toolkit";


const basicSlice = createSlice({
    name: "basic",
    initialState: {
        visible: false,
        navbar: false,
    },
    reducers: {
        show(state) {
            state.visible = true;
        },
        hide(state) {
            state.visible = false;
        },
        showNavBar(state) {
            state.navbar = true;
        },
        hideNavBar(state) {
            state.navbar = false;
        }
    }
});

export const showLoader = (state) => state.basic.visible;


export const { show, hide,showNavBar,hideNavBar } = basicSlice.actions;

export const showVisibleStatus = (state) => state.basic.visible;
export const showNavbarStatus = (state) => state.basic.navbar;

export default basicSlice.reducer;