import { Link } from 'react-router-dom';

const ProductItem = ({ item }) => {
    const images = [
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03106_3.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03105_3.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-CRSP783.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/IMG_1607copy.jpg',
    ];

    const thumnail = [
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03106_1.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-JSDY03105_1.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/FM-CRSP783.jpg',
        'https://cdn.shopify.com/s/files/1/0563/0545/1197/products/2389-1.jpg'
    ];

    return (
        <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="prd-box text-center">
                <Link to={`/products-range/${item.header_id}`}>
                    <div className="prd-img">
                        {item.images[0] ? (
                            <>
                                <img src={item.images[0]?.url} className="img-fluid" />
                                <img src={item.images[0]?.url} className="img-fluid hover-img" />
                            </>) : (
                            <>
                                <img src="https://testcadomain.com/cdl/public/img/CDL-Charcoal.png" className="img-fluid" />
                                <img src="https://testcadomain.com/cdl/public/img/CDL-Charcoal.png" className="img-fluid hover-img" />
                            </>)}

                    </div>
                    <h4>{item.title}</h4>
                </Link>
            </div>
        </div>
    )
}

export default ProductItem; 