import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: 'product',
    initialState: {
        product_lists: [],
        product_count: 0,
        pagination_link: {},
    },
    reducers: {
        addProducts(state, action) {
            state.product_lists = action.payload.data
            state.pagination_link = action.payload
        },
    }
});

export const { addProducts } = productSlice.actions;
export default productSlice.reducer;