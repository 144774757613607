import React from 'react';

import AboutTopSection from './components/top-section';
import VMPWrapper from './components/vmp-wrapper';
import Journey from './components/journey';
import OurAchievement from './components/our-achievement';
import WhyUs from './components/why-us';
import Certificates from './components/certificates';

const AboutUs = () => {

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        centerMode: true,
        centerPadding: 0,
        speed: 500,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 1
    }
    return (
        <div className="content-wrapper">
            
            <AboutTopSection />

            <VMPWrapper />

            <Journey />

            <OurAchievement />

            <WhyUs />

            <Certificates settings={settings} />
        </div>
    )
}

export default AboutUs
