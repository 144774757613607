import HeroBanner from '../components/hero-banner';
import About from '../components/about';
import Products from '../components/products';
import Blogs from '../components/blogs';
import Customers from '../components/customers';

function home() {

  return (
    <>
      <div className="App">

        < HeroBanner />

        <About />

        <Products />

        <Blogs />

        <Customers />

      </div>
    </>
  );
}

export default home;
